import { SelectedPlanningOverviewOptions } from "../components/Planning/PlanningOverviewAccordionItem";
import Application from "../types/Application";
import PlannedApplicationSite from "../types/PlannedApplicationSite";
import Site from "../types/Site";
import { getTimeSpanString, getWeekDayName, getWeekNumber, removeTimeFromDate } from "./dateUtils";
import { SiteType } from "./enums";

export function hasTwoOccasions(plannedApplicationSite: PlannedApplicationSite): boolean {
    const hasTwoOccasions =
        !!plannedApplicationSite.occasion2Starts && plannedApplicationSite.occasion2Starts.getFullYear() > 1;

    return hasTwoOccasions;
}

export function applyPlanningOverviewFilter(
    selectedOptions: SelectedPlanningOverviewOptions,
    plannedApplicationSites: PlannedApplicationSite[]
) {
    if (!selectedOptions) return plannedApplicationSites;

    let filtered = plannedApplicationSites;

    if (selectedOptions.cultureProviderId) {
        filtered = filtered.filter((pas) => pas.application.cultureProviderId == selectedOptions.cultureProviderId);
    }

    if (selectedOptions.applicationId) {
        filtered = filtered.filter((pas) => pas.applicationId == selectedOptions.applicationId);
    }

    if (selectedOptions.siteId) {
        filtered = filtered.filter((pas) => pas.siteId == selectedOptions.siteId);
    }

    if (selectedOptions.searchText) {
        const lowerCaseText = selectedOptions.searchText.toLocaleLowerCase();

        filtered = filtered.filter(
            (pas) =>
                pas.site.name.toLocaleLowerCase().includes(lowerCaseText) ||
                (pas.numberOfSeats
                    ? pas.numberOfSeats.toString().includes(lowerCaseText)
                    : Math.min(pas.site.numberOfSeats, pas.application.document.maxNumberOfSeats)
                          .toString()
                          .includes(lowerCaseText)) ||
                pas.occasion1Starts.toLocaleDateString().includes(lowerCaseText) ||
                getWeekNumber(pas.occasion1Starts).toString().includes(lowerCaseText) ||
                getWeekDayName(pas.occasion1Starts).toLocaleLowerCase().includes(lowerCaseText) ||
                getTimeSpanString(pas.occasion1Starts, pas.occasion1Ends).includes(lowerCaseText) ||
                (hasTwoOccasions(pas) &&
                    getTimeSpanString(pas.occasion2Starts, pas.occasion2Ends).includes(lowerCaseText)) ||
                SiteType[pas.site.siteType as keyof typeof SiteType].toLocaleLowerCase().includes(lowerCaseText) ||
                pas.application.actorName.toLocaleLowerCase().includes(lowerCaseText) ||
                pas.application.document.eventName.toLocaleLowerCase().includes(lowerCaseText)
        );
    }

    if (selectedOptions.fromDate) {
        filtered = filtered.filter((pas) => selectedOptions.fromDate <= removeTimeFromDate(pas.occasion1Starts));
    }

    if (selectedOptions.toDate) {
        filtered = filtered.filter((pas) => removeTimeFromDate(pas.occasion1Starts) <= selectedOptions.toDate);
    }

    return filtered;
}

export function getDefaultNumberOfSeats(application: Application, site: Site): number {
    let defaultNumberOfSeats = 0;

    if (application && site) {
        defaultNumberOfSeats = Math.min(application.document.maxNumberOfSeats, site.numberOfSeats);
    } else if (application) {
        defaultNumberOfSeats = application.document.maxNumberOfSeats;
    } else if (site) {
        defaultNumberOfSeats = site.numberOfSeats;
    }

    return defaultNumberOfSeats;
}

export function getNumberOfSeats(plannedApplicationSite: PlannedApplicationSite): number {
    let numberOfSeats = 0;

    if (plannedApplicationSite.numberOfSeats || plannedApplicationSite.numberOfSeats === 0) {
        numberOfSeats = plannedApplicationSite.numberOfSeats;
    } else if (plannedApplicationSite.application && plannedApplicationSite.site) {
        numberOfSeats = getDefaultNumberOfSeats(plannedApplicationSite.application, plannedApplicationSite.site);
    }

    return numberOfSeats;
}

export function sortPlannedApplicationSites(
    plannedApplicationSites: PlannedApplicationSite[]
): PlannedApplicationSite[] {
    const sorted = plannedApplicationSites.sort((a, b) => {
        if (!a.site || !b.site || a.site.name === b.site.name) {
            return a.occasion1Starts.getTime() - b.occasion1Starts.getTime();
        }
        return a.site.name > b.site.name ? 1 : -1;
    });

    return sorted;
}

import React, { useState } from "react";
import { ApplicationPlanningStatus } from "../../../common/enums";
import { isSameDay, toDateOnlyString, toDayAndMonthString } from "../../../common/dateUtils";
import DateFilterModal from "../DateFilterModal";
import ApplicationPeriod from "../../../types/ApplicationPeriod";

export type ApplicationPlanningFilterOptions = {
    searchText?: string;
    applicationPlanningStatus?: ApplicationPlanningStatus;
    possibleOccasionDates?: Date[];
};

type ApplicationPlanningFilterProps = {
    selectedFilterOptions: ApplicationPlanningFilterOptions;
    onSelectedFilterOptionsChange: (updatedProperty: keyof ApplicationPlanningFilterOptions, updatedValue: any) => void;
    applicationPeriod: ApplicationPeriod;
};

const applicationPlanningStatusOptions = Object.entries(ApplicationPlanningStatus).map((entry) => (
    <option key={entry[0]} value={entry[1]}>
        {entry[1]}
    </option>
));

function ApplicationPlanningFilter({
    selectedFilterOptions,
    onSelectedFilterOptionsChange,
    applicationPeriod
}: ApplicationPlanningFilterProps) {
    const [showDateModal, setShowPossibleDateFilterModal] = useState(false);

    const dateAddedHandler = (addedDate: Date) => {
        const previousAvailableDates = selectedFilterOptions?.possibleOccasionDates ?? [];

        const updatedDates = [...previousAvailableDates, addedDate].sort((a, b) => a.getTime() - b.getTime());

        onSelectedFilterOptionsChange("possibleOccasionDates", updatedDates);
    };

    const dateRemovedHandler = (removedDate: Date) => {
        const updatedDates = selectedFilterOptions.possibleOccasionDates.filter((d) => !isSameDay(d, removedDate));

        onSelectedFilterOptionsChange("possibleOccasionDates", updatedDates);
    };

    const availableDatesString =
        selectedFilterOptions && selectedFilterOptions.possibleOccasionDates
            ? selectedFilterOptions.possibleOccasionDates.map((d) => toDayAndMonthString(d)).join(", ")
            : "";

    return (
        <>
            <div className="row mb-3">
                <div className="col-md-3">
                    <label className="form-label mb-0" htmlFor="search-input">
                        Fritextsök
                    </label>
                    <input
                        className="form-control"
                        type="text"
                        id="search-input"
                        placeholder="Sök på namn eller aktör"
                        value={
                            selectedFilterOptions && selectedFilterOptions.searchText
                                ? selectedFilterOptions.searchText
                                : ""
                        }
                        onChange={(event) => onSelectedFilterOptionsChange("searchText", event.target.value)}
                    />
                </div>
                <div className="col-md-3">
                    <label className="form-label mb-0" htmlFor="application-planning-status">
                        Programläggningsstatus
                    </label>
                    <select
                        className="form-select"
                        id="application-planning-status"
                        onChange={(event) =>
                            onSelectedFilterOptionsChange("applicationPlanningStatus", event.target.value)
                        }
                    >
                        <option value="">- Alla -</option>
                        {applicationPlanningStatusOptions}
                    </select>
                </div>
                <div className="col-md-3">
                    <label className="form-label mb-0" htmlFor="available-dates-filter-options">
                        Möjliga datum
                    </label>
                    <div className="input-group">
                        <input
                            id="available-dates-filter-options"
                            type="text"
                            className="form-control"
                            readOnly={true}
                            onClick={() => setShowPossibleDateFilterModal(true)}
                            value={availableDatesString}
                        />
                        <div className="input-group-append">
                            <button
                                className="btn btn-primary"
                                type="button"
                                style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                                onClick={() => setShowPossibleDateFilterModal(true)}
                            >
                                <i className="bi bi-calendar-fill"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <DateFilterModal
                onDateAdded={dateAddedHandler}
                onDateRemoved={dateRemovedHandler}
                onHide={() => setShowPossibleDateFilterModal(false)}
                selectedDates={selectedFilterOptions?.possibleOccasionDates ?? []}
                show={showDateModal}
                applicationPeriod={applicationPeriod}
            />
        </>
    );
}

export default ApplicationPlanningFilter;

import React, { CSSProperties, useEffect, useRef, useState } from "react";
import Site from "../../types/Site";
import {
    fetchApplicationPeriod,
    fetchPlannedApplications,
    fetchAvailableSiteTimeBySiteIdAndApplicationPeriodId,
    fetchPlannedApplicationSitesBySiteIdAndApplicationPeriodId,
    fetchSiteById
} from "../../common/apiClient";
import { pageLoadErrorMessage } from "../../common/errorMessages";
import PlannedApplicationSite from "../../types/PlannedApplicationSite";
import LoadingSpinner from "../Common/LoadingSpinner";
import ErrorMessage from "../Common/Messages/ErrorMessage";
import ApplicationPeriod from "../../types/ApplicationPeriod";
import { copyRichText } from "../../common/stringUtils";
import SuccessMessage from "../Common/Messages/SuccessMessage";
import {
    defaultFontFamily,
    defaultMarginBottom,
    defaultTextStyles,
    sectionHeaderStyles,
    tableCellHeaderStyles,
    tableCellStyles,
    tableStyles
} from "../../common/inlineStyles";
import SiteScheduleTableRow from "./SiteScheduleTableRow";
import { SiteType } from "../../common/enums";

type SiteContractCreationProps = {
    siteId: number;
    applicationPeriodId: number;
};

function SiteContractCreation({ siteId, applicationPeriodId }: SiteContractCreationProps) {
    const [site, setSite] = useState<Site>();
    const [plannedApplicationSites, setPlannedApplicationSites] = useState<PlannedApplicationSite[]>([]);
    const [applicationPeriod, setApplicationPeriod] = useState<ApplicationPeriod>();
    const [availableSiteTime, setAvailableSiteTime] = useState<AvailableSiteTime>();
    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");
    const [copiedToClipboard, setCopiedToClipboard] = useState(false);
    const [organizationInput, setOrganizationInput] = useState("");
    const [costInput, setCostInput] = useState("");
    const [featuresCommentInput, setFeaturesCommentInput] = useState("");
    const [generalCommentInput, setGeneralCommentInput] = useState("");

    const contractRef = useRef<HTMLDivElement>();
    const successMessageRef = useRef<HTMLDivElement>();

    useEffect(() => {
        const fetchData = async () => {
            const [
                siteFetchResult,
                plannedApplicationSitesFetchResult,
                applicationsFetchResult,
                applicationPeriodFetchResult,
                availableSiteTimeFetchResult
            ] = await Promise.all([
                fetchSiteById(siteId),
                fetchPlannedApplicationSitesBySiteIdAndApplicationPeriodId(siteId, applicationPeriodId),
                fetchPlannedApplications(siteId, applicationPeriodId),
                fetchApplicationPeriod(applicationPeriodId),
                fetchAvailableSiteTimeBySiteIdAndApplicationPeriodId(siteId, applicationPeriodId)
            ]);

            if (siteFetchResult.errorMessage) {
                console.error(siteFetchResult.errorMessage);
                setErrorMessage(pageLoadErrorMessage);
                setIsLoading(false);
                return;
            }

            if (plannedApplicationSitesFetchResult.errorMessage) {
                console.error(plannedApplicationSitesFetchResult.errorMessage);
                setErrorMessage(pageLoadErrorMessage);
                setIsLoading(false);
                return;
            }

            if (applicationsFetchResult.errorMessage) {
                console.error(applicationsFetchResult.errorMessage);
                setErrorMessage(pageLoadErrorMessage);
                setIsLoading(false);
                return;
            }

            if (applicationPeriodFetchResult.errorMessage) {
                console.error(applicationPeriodFetchResult.errorMessage);
                setErrorMessage(pageLoadErrorMessage);
                setIsLoading(false);
                return;
            }

            if (availableSiteTimeFetchResult.errorMessage) {
                console.error(availableSiteTimeFetchResult.errorMessage);
                setErrorMessage(pageLoadErrorMessage);
                setIsLoading(false);
                return;
            }

            plannedApplicationSitesFetchResult.value.sort(
                (a, b) => a.occasion1Starts.getTime() - b.occasion1Starts.getTime()
            );

            plannedApplicationSitesFetchResult.value.forEach((pas) => {
                const plannedApplication = applicationsFetchResult.value.find((a) => a.id === pas.applicationId);

                if (!plannedApplication) {
                    console.error(`Ingen intresseanmälan med id ${pas.applicationId} skickades från backend.`);
                    setErrorMessage(pageLoadErrorMessage);
                    return;
                }

                pas.site = site;
                pas.application = plannedApplication;
            });

            setSite(siteFetchResult.value);
            setPlannedApplicationSites(plannedApplicationSitesFetchResult.value);
            setApplicationPeriod(applicationPeriodFetchResult.value);
            setAvailableSiteTime(availableSiteTimeFetchResult.value);
            setIsLoading(false);
        };
        fetchData();
    }, []);

    if (isLoading) {
        return <LoadingSpinner />;
    }

    if (errorMessage) {
        return <ErrorMessage message={errorMessage} />;
    }

    const copyToClipboardClickHandler = async () => {
        await copyRichText(contractRef.current.innerHTML);

        if (!copiedToClipboard) {
            setCopiedToClipboard(true);
            setTimeout(() => {
                successMessageRef.current.classList.add("slide-out");
                setTimeout(() => {
                    setCopiedToClipboard(false);
                }, 80);
            }, 3000);
        }
    };

    const scheduleTableRows: JSX.Element[] = plannedApplicationSites.map((pas) => (
        <SiteScheduleTableRow plannedApplicationSite={pas} availableSiteTime={availableSiteTime} key={pas.id} />
    ));

    const availableTechnology: string[] = [];

    if (site.hasPA) {
        availableTechnology.push("PA");
    }
    if (site.hasLighting) {
        availableTechnology.push("Ljus");
    }
    if (site.hasSound) {
        availableTechnology.push("Ljud");
    }
    if (site.hasBlackbox) {
        availableTechnology.push("Blackbox");
    }
    if (site.hasBlackout) {
        availableTechnology.push("Mörkläggning");
    }

    return (
        <div className="mt-3">
            {copiedToClipboard ? (
                <div className="slide-alert" ref={successMessageRef}>
                    <SuccessMessage message="Avtalsunderlaget har kopierats!" />
                </div>
            ) : (
                <></>
            )}
            <h2 className="d-flex justify-content-center">
                Avtalsunderlag: Lokal {site.name} {site.roomName}, spelperiod{" "}
                {applicationPeriod.occasionsFrom.toLocaleDateString()}
            </h2>

            <div className="m-auto" style={{ width: "800px" }}>
                <div className="mt-2 mb-2">
                    <label className="form-label">Förvaltning/organisation/förening</label>
                    <input
                        className="form-control"
                        value={organizationInput}
                        onChange={(e) => setOrganizationInput(e.target.value)}
                    />
                </div>

                <div className="mt-2 mb-2">
                    <label className="form-label">Avtalad hyra/totalkostnad</label>
                    <input className="form-control" value={costInput} onChange={(e) => setCostInput(e.target.value)} />
                </div>

                <div className="mt-2 mb-2">
                    <label className="form-label">Kommentar om teknik</label>
                    <textarea
                        className="form-control"
                        value={featuresCommentInput}
                        onChange={(e) => setFeaturesCommentInput(e.target.value)}
                        cols={5}
                        rows={3}
                    ></textarea>
                </div>

                <div className="mt-2 mb-5">
                    <label className="form-label">Övrig kommentar/förtydligande</label>
                    <textarea
                        className="form-control"
                        value={generalCommentInput}
                        onChange={(e) => setGeneralCommentInput(e.target.value)}
                        cols={5}
                        rows={3}
                    ></textarea>
                </div>

                <button className="btn btn-primary mb-2" onClick={copyToClipboardClickHandler}>
                    <i className="bi bi-clipboard me-1"></i>
                    Kopiera avtalsunderlag till urklipp
                </button>

                <div className="border rounded p-5" ref={contractRef}>
                    <div style={{ margin: "auto", width: "700px" }}>
                        <div
                            style={{
                                marginBottom: defaultMarginBottom,
                                textAlign: "center",
                                fontFamily: defaultFontFamily,
                                fontSize: "24pt"
                            }}
                        >
                            Överenskommelse
                        </div>
                        <div
                            style={{
                                marginBottom: defaultMarginBottom,
                                textAlign: "center",
                                fontWeight: "bold",
                                fontFamily: defaultFontFamily
                            }}
                        >
                            om KuBo mellan Göteborgs Stads kulturförvaltning och nedanstående spelplats
                        </div>
                        <div style={sectionHeaderStyles}>Spelplats</div>
                        <table style={tableStyles}>
                            <tbody>
                                <tr>
                                    <td style={{ width: "50%", ...tableCellStyles }} colSpan={1}>
                                        <span style={tableCellHeaderStyles}>Platsnamn</span> <br />
                                        {site.name}
                                    </td>
                                    <td style={{ width: "50%", ...tableCellStyles }} colSpan={1}>
                                        <span style={tableCellHeaderStyles}>Förvaltning/organisation/förening</span>{" "}
                                        <br />
                                        {organizationInput}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <br />
                        <table style={tableStyles}>
                            <tbody>
                                <tr>
                                    <td style={{ ...tableCellStyles }} colSpan={2}>
                                        <span style={tableCellHeaderStyles}>Besöksadress</span> <br />
                                        {site.address}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ width: "50%", ...tableCellStyles }} colSpan={1}>
                                        <span style={tableCellHeaderStyles}>Telefon till spelplats</span> <br />
                                        {site.phoneNumber}
                                    </td>
                                    <td style={{ width: "50%", ...tableCellStyles }} colSpan={1}>
                                        <span style={tableCellHeaderStyles}>Ansvarig för spelplatsen</span> <br />
                                        {site.contactPersonName}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ ...tableCellStyles }} colSpan={2}>
                                        <span style={tableCellHeaderStyles}>Typ av lokal/verksamhet</span> <br />
                                        {SiteType[site.siteType as keyof typeof SiteType]}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <br />
                        <div style={sectionHeaderStyles}>Spelplatsens organisationsuppgifter/fakturauppgifter</div>
                        <table style={tableStyles}>
                            <tbody>
                                <tr>
                                    <td style={{ width: "50%", ...tableCellStyles }} colSpan={3}>
                                        <span style={tableCellHeaderStyles}>Verksamhetens namn</span> <br />
                                        {site.organizationName}
                                    </td>
                                    <td style={{ width: "50%", ...tableCellStyles }} colSpan={3}>
                                        <span style={tableCellHeaderStyles}>Organisationsnummer</span> <br />
                                        {site.organizationNumber}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ width: "33.3%", ...tableCellStyles }} colSpan={2}>
                                        <span style={tableCellHeaderStyles}>Gatuadress/Box</span> <br />
                                        {site.organizationStreetAddress}
                                    </td>
                                    <td style={{ width: "33.3%", ...tableCellStyles }} colSpan={2}>
                                        <span style={tableCellHeaderStyles}>Postnummer</span> <br />
                                        {site.organizationPostalCode}
                                    </td>
                                    <td style={{ width: "33.3%", ...tableCellStyles }} colSpan={2}>
                                        <span style={tableCellHeaderStyles}>Ort</span> <br />
                                        {site.organizationPostalAddress}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ ...tableCellStyles }} colSpan={6}>
                                        <span style={tableCellHeaderStyles}>Referens</span> <br />
                                        {site.organizationReference}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <br />
                        <div style={sectionHeaderStyles}>
                            Kontaktperson på spelplatsen för artister och praktiska frågor
                        </div>
                        <table style={tableStyles}>
                            <tbody>
                                <tr>
                                    <td style={{ width: "50%", ...tableCellStyles }} colSpan={1}>
                                        <span style={tableCellHeaderStyles}>Namn</span> <br />
                                        {site.contactPersonName}
                                    </td>
                                    <td style={{ width: "50%", ...tableCellStyles }} colSpan={1}>
                                        <span style={tableCellHeaderStyles}>Befattning</span> <br />
                                        {site.contactPersonPosition}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ width: "50%%", ...tableCellStyles }} colSpan={1}>
                                        <span style={tableCellHeaderStyles}>Telefon</span> <br />
                                        {site.contactPersonPhoneNumber}
                                    </td>
                                    <td style={{ width: "50%%", ...tableCellStyles }} colSpan={1}>
                                        <span style={tableCellHeaderStyles}>E-post</span> <br />
                                        {site.contactPersonEmail}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ ...tableCellStyles }} colSpan={2}>
                                        <span style={tableCellHeaderStyles}>Avser nyttjande av lokalen </span> <br />
                                        {site.roomName}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <br />

                        <div style={sectionHeaderStyles}>Avser följande speltider och föreställningar</div>
                        <table style={tableStyles}>
                            <thead>
                                <tr>
                                    <th style={{ ...tableCellStyles, minWidth: "5rem" }}>Datum</th>
                                    <th style={{ ...tableCellStyles, minWidth: "6rem" }}>
                                        tillgång kl. x – x (inkl get-in/-out)
                                    </th>
                                    <th style={tableCellStyles}>Föreställning</th>
                                    <th style={tableCellStyles}>Fst 1</th>
                                    <th style={tableCellStyles}>Fst 2</th>
                                    <th style={tableCellStyles}>Aktör</th>
                                    <th style={tableCellStyles}>Aktörens kontaktuppgifter</th>
                                </tr>
                            </thead>
                            <tbody>{scheduleTableRows}</tbody>
                        </table>
                        <br />
                        <div style={sectionHeaderStyles}>Utrustning Spelplatsen/Värdscenen tillhandahåller</div>
                        <table style={tableStyles}>
                            <tbody>
                                <tr>
                                    <td style={{ ...tableCellStyles }} colSpan={1}>
                                        <span style={tableCellHeaderStyles}>Tekniker </span> <br />
                                        {availableTechnology.join(", ")}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ ...tableCellStyles }} colSpan={1}>
                                        <span style={tableCellHeaderStyles}>Förtydligange om tekniker</span> <br />
                                        {site.otherInformation}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ ...tableCellStyles }} colSpan={1}>
                                        <span style={tableCellHeaderStyles}>Övriga befintliga faciliteter</span> <br />
                                        {site.otherFacilities}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <br />
                        
                        {featuresCommentInput ? (
                            <>
                                <div style={sectionHeaderStyles}>Kommentar om tekniker</div>
                                <div style={defaultTextStyles}>{featuresCommentInput}</div>
                                <br />
                            </>
                        ) : (
                            <></>
                        )}

                        <div style={sectionHeaderStyles}>
                            KuBo: kultur-, grundskole- och förskoleförvaltningen tillhandahåller
                        </div>
                        <ul>
                            <li style={defaultTextStyles}>
                                Inköp av publika konstupplevelser för scen så som musik, teater och dans
                            </li>
                            <li style={defaultTextStyles}>
                                Administration; avtal med aktörer samt hanteringen av bokningssidan
                            </li>
                            <li style={defaultTextStyles}>
                                Publikarbete riktat till förskolor och skolor genom bokningssidan KuBo samt i samverkan
                                med kultursamordnare på grundskole- respektive förskoleförvaltningen
                            </li>
                            <li style={defaultTextStyles}>
                                Erbjuder spelplatsen möjligheten till, i mån av plats, biljetter för offentlig publik
                            </li>
                            <li style={defaultTextStyles}>
                                Statistikuppföljning, analys och rapport kopplat till mål i Göteborg stads plan för barn
                                och ungkultur
                            </li>
                            <li style={defaultTextStyles}>Utveckling av bokningssidan </li>
                        </ul>
                        <div style={sectionHeaderStyles}>Spelplatsen/Värdscenen åtar sig att</div>
                        <ul>
                            <li style={defaultTextStyles}>
                                Tillhandahålla en städad och iordningställd lokal med tillgängliga sittplatser anpassade
                                efter arrangemangets behov.
                            </li>
                            <li style={defaultTextStyles}>
                                Ansvara för att facilitera arrangemanget och bistå med personal
                            </li>
                            <li style={defaultTextStyles}>Delta vid planeringsmöten upp till 2 ggr/termin</li>
                            <li style={defaultTextStyles}>Ta kontakt med aktörerna i god tid inför arrangemanget </li>
                            <li style={defaultTextStyles}>
                                Ta emot aktörerna och visa dem till rätta, är bärhjälp samt behjälplig vid bygg/riv
                            </li>
                            <li style={defaultTextStyles}>Tillhandahålla aktörerna kaffe, te/vatten</li>
                            <li style={defaultTextStyles}>Ta emot barn och elever</li>
                            <li style={defaultTextStyles}>
                                Ha rollen som publikvärd i KuBos bokningssida, göra närvarorapport vid avvikelser
                            </li>
                        </ul>
                        <div style={sectionHeaderStyles}>ÄNDRING AV ÖVERENSKOMMELSE</div>
                        <div style={defaultTextStyles}>
                            Samtliga ändringar och tillägg till detta avtal ska göras skriftligen och undertecknas av
                            båda parter. Om någon av parterna inte kan fullfölja uppdraget enligt detta avtal skall
                            detta skyndsamt meddelas övriga parter. Om avbokning av lokalen görs av
                            Kulturförvaltningen/KuBo, och om hyra ingår i avtalet kommer den ändå utbetalas. Om
                            avbokning av lokalen sker av Spelplatsen utgår ingen hyra.
                        </div>
                        <br />
                        <div style={sectionHeaderStyles}>Antal personer </div>
                        <div style={defaultTextStyles}>
                            Antal besökare får ej överstiga det antal angivet i respektive lokal. Vid överträdelse kan
                            personal och/eller brandmyndigheterna stänga lokalen.
                        </div>
                        <br />
                        <div style={sectionHeaderStyles}>Ansvar vid stöld </div>
                        <div style={defaultTextStyles}>
                            Spelplatsen/Värdscenen ansvar inte för aktörens eller besökarnas kläder eller annan lös
                            egendom som förvaras i lokalen.
                        </div>
                        <br />
                        <div style={sectionHeaderStyles}>Kontaktpersoner på KuBo</div>
                        <table style={tableStyles}>
                            <tbody>
                                <tr>
                                    <td style={{ width: "33.33%", ...tableCellStyles }} colSpan={1}>
                                        Vendela Källström, för innehåll
                                    </td>
                                    <td style={{ width: "33.33%", ...tableCellStyles }} colSpan={1}>
                                        0764–95 04 14
                                    </td>
                                    <td style={{ width: "33.33%", ...tableCellStyles }} colSpan={1}>
                                        <a href="mailto:vendela.kallstrom@kultur.goteborg.se">
                                            vendela.kallstrom@kultur.goteborg.se
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ width: "33.33%", ...tableCellStyles }} colSpan={1}>
                                        Therese Wigren, för fakturafrågor
                                    </td>
                                    <td style={{ width: "33.33%", ...tableCellStyles }} colSpan={1}>
                                        031-368 36 97
                                    </td>
                                    <td style={{ width: "33.33%", ...tableCellStyles }} colSpan={1}>
                                        <a href="mailto:therese.wigren@kultur.goteborg.se">
                                            therese.wigren@kultur.goteborg.se
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <br />

                        <div style={sectionHeaderStyles}>Samverkan</div>
                        <div style={defaultTextStyles}>Avtalad hyra/totalkostnad: {costInput}</div>
                        <br />

                        {generalCommentInput ? (
                            <>
                                <div style={sectionHeaderStyles}>Kommentar/förtydligande</div>
                                <div style={defaultTextStyles}>{generalCommentInput}</div>
                                <br />
                            </>
                        ) : (
                            <></>
                        )}

                        <div style={defaultTextStyles}>
                            Fakturering av lokalkostnad görs via{" "}
                            <span style={{ fontWeight: "bold", textDecoration: "underline" }}>e-faktura</span> med
                            följande uppgifter:
                        </div>
                        <div style={{ fontSize: "10pt" }}>N690/Kulturförvaltningen/ Intraservice</div>
                        <div style={{ fontSize: "10pt" }}>Kulturförvaltningens GLN nr: 7381035001594</div>
                        <div style={{ fontSize: "10pt" }}>Referens: Therese Wigren, ref.nr 3260</div>
                        <div style={{ fontSize: "10pt" }}>Organisationsnummer 212000–1355</div>
                        <div style={{ fontSize: "10pt" }}>405 38 Göteborg</div>
                        <div style={{ fontSize: "10pt" }}>Vat: SE212000135501</div>
                        <br />

                        <div style={sectionHeaderStyles}>UNDERSKRIFT</div>
                        <div style={defaultTextStyles}>
                            Avtalet har signerats av Kulturförvaltningen i Göteborg och avtalspart genom digital
                            signering.
                        </div>
                        <br />

                        <div style={{ fontWeight: "bold", ...defaultTextStyles }}>Underskrifter</div>
                        <div style={defaultTextStyles}>Digitala signaturer återfinns på sista sida.</div>
                        <br />

                        <div style={defaultTextStyles}>
                            För Göteborgs stads Kulturförvaltning: Ida Svanberg, enhetschef
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SiteContractCreation;

import React, { useState } from "react";
import { CityArea } from "../../../common/enums";
import { isSameDay, toDayAndMonthString } from "../../../common/dateUtils";
import DateFilterModal from "../DateFilterModal";
import ApplicationPeriod from "../../../types/ApplicationPeriod";

export type ApplicationSiteSelectionFilterOptions = {
    cityArea: CityArea;
    searchText: string;
    availableDates: Date[];
};

type ApplicationSiteSelectionFilterProps = {
    selectedFilterOptions: ApplicationSiteSelectionFilterOptions;
    onSelectedFilterOptionsChange: (
        updatedProperty: keyof ApplicationSiteSelectionFilterOptions,
        updatedValue: any
    ) => void;
    applicationPeriod: ApplicationPeriod;
};

const cityAreaOptions = Object.entries(CityArea).map((entry) => (
    <option key={entry[0]} value={entry[0]}>
        {entry[1]}
    </option>
));

function ApplicationSiteSelectionFilter({
    selectedFilterOptions,
    onSelectedFilterOptionsChange,
    applicationPeriod
}: ApplicationSiteSelectionFilterProps) {
    const [showDateModal, setShowDateModal] = useState(false);

    const dateAddedHandler = (addedDate: Date) => {
        const previousAvailableDates = selectedFilterOptions?.availableDates ?? [];

        const updatedDates = [...previousAvailableDates, addedDate].sort((a, b) => a.getTime() - b.getTime());

        onSelectedFilterOptionsChange("availableDates", updatedDates);
    };
    
    const dateRemovedHandler = (removedDate: Date) => {
        const updatedDates = selectedFilterOptions.availableDates.filter((d) => !isSameDay(d, removedDate));

        onSelectedFilterOptionsChange("availableDates", updatedDates);
    };

    const filteredAvailableDates = selectedFilterOptions?.availableDates ?? [];

    const availableDatesString = filteredAvailableDates.map((d) => toDayAndMonthString(d)).join(", ");

    return (
        <>
            <div className="row mb-2">
                <div className="col-md-3 form-group">
                    <label className="form-label m-0">Stadsområde</label>
                    <select
                        className="form-select"
                        value={selectedFilterOptions ? selectedFilterOptions.cityArea : ""}
                        onChange={(event) => onSelectedFilterOptionsChange("cityArea", event.target.value)}
                    >
                        <option value={""}>- Alla stadsområden -</option>
                        {cityAreaOptions}
                    </select>
                </div>
                <div className="col-md-3 form-group">
                    <label className="form-label m-0">Fritextsök</label>
                    <input
                        className="form-control"
                        placeholder="Sök på plats eller lokalnamn"
                        value={selectedFilterOptions ? selectedFilterOptions.searchText : ""}
                        onChange={(event) => onSelectedFilterOptionsChange("searchText", event.target.value)}
                    ></input>
                </div>
                <div className="col-md-3">
                    <label className="form-label mb-0" htmlFor="available-dates-filter-options">
                        Tillgängliga datum
                    </label>
                    <div className="input-group">
                        <input
                            id="available-dates-filter-options"
                            type="text"
                            className="form-control"
                            readOnly={true}
                            onClick={() => setShowDateModal(true)}
                            value={availableDatesString}
                        />
                        <div className="input-group-append">
                            <button
                                className="btn btn-primary"
                                type="button"
                                style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                                onClick={() => setShowDateModal(true)}
                            >
                                <i className="bi bi-calendar-fill"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <DateFilterModal
                onDateAdded={dateAddedHandler}
                onDateRemoved={dateRemovedHandler}
                onHide={() => setShowDateModal(false)}
                selectedDates={selectedFilterOptions?.availableDates ?? []}
                show={showDateModal}
                applicationPeriod={applicationPeriod}
            />
        </>
    );
}

export default ApplicationSiteSelectionFilter;

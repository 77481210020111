import React from "react";
import { Modal } from "react-bootstrap";
import { DayPicker } from "react-day-picker";
import { getCaptionText, getWeekDayName, isSameDay, toDateOnlyString, toDayAndMonthString } from "../../common/dateUtils";
import ApplicationPeriod from "../../types/ApplicationPeriod";

type DateFilterModalProps = {
    show: boolean;
    onHide: () => void;
    selectedDates: Date[];
    onDateAdded: (addedDate: Date) => void;
    onDateRemoved: (removedDate: Date) => void;
    applicationPeriod: ApplicationPeriod;
};

function DateFilterModal({
    show,
    onHide,
    selectedDates,
    onDateAdded,
    onDateRemoved,
    applicationPeriod
}: DateFilterModalProps) {
    const dateClickHandler = (clickedDate: Date) => {
        const dateWasPreviouslySelected = selectedDates.some((d) => isSameDay(d, clickedDate));

        if (dateWasPreviouslySelected) {
            onDateRemoved(clickedDate);
            return;
        }

        onDateAdded(clickedDate);
    };

    const selectedDatesString = selectedDates
        .map((d) => toDayAndMonthString(d))
        .join(", ");

    const defaultMonth = selectedDates.length > 0 ? selectedDates[0] : applicationPeriod.occasionsFrom;

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
                <input type="text" className="form-control w-75 m-auto" disabled value={selectedDatesString} />
                <DayPicker
                    className="d-flex justify-content-center"
                    weekStartsOn={1}
                    formatters={{
                        formatWeekdayName: getWeekDayName,
                        formatCaption: getCaptionText
                    }}
                    defaultMonth={defaultMonth}
                    selected={selectedDates}
                    onDayClick={dateClickHandler}
                />
            </Modal.Body>
        </Modal>
    );
}

export default DateFilterModal;

import React, { useState } from "react";
import { SitePlanningStatus } from "../../../common/enums";
import { isSameDay, toDayAndMonthString } from "../../../common/dateUtils";
import DateFilterModal from "../DateFilterModal";
import ApplicationPeriod from "../../../types/ApplicationPeriod";

export type SitePlanningFilterOptions = {
    searchText?: string;
    sitePlanningStatus?: SitePlanningStatus;
    availableDates?: Date[];
};

type SitePlanningFilterProps = {
    selectedFilterOptions: SitePlanningFilterOptions;
    onSelectedFilterOptionsChange: (updatedProperty: keyof SitePlanningFilterOptions, updatedValue: any) => void;
    applicationPeriod: ApplicationPeriod;
};

const sitePlanningStatusOptions = Object.values(SitePlanningStatus).map((status) => (
    <option key={status} value={status}>
        {status}
    </option>
));

function SitePlanningFilter({
    selectedFilterOptions,
    onSelectedFilterOptionsChange,
    applicationPeriod
}: SitePlanningFilterProps) {
    const [showDateModal, setShowDateModal] = useState(false);

    const dateAddedHandler = (addedDate: Date) => {
        const previousAvailableDates = selectedFilterOptions?.availableDates ?? [];

        const updatedDates = [...previousAvailableDates, addedDate].sort((a, b) => a.getTime() - b.getTime());

        onSelectedFilterOptionsChange("availableDates", updatedDates);
    };

    const dateRemovedHandler = (removedDate: Date) => {
        const updatedDates = selectedFilterOptions.availableDates.filter((d) => !isSameDay(d, removedDate));

        onSelectedFilterOptionsChange("availableDates", updatedDates);
    };

    const availableDates = selectedFilterOptions?.availableDates ?? [];

    const availableDatesString = availableDates.map((d) => toDayAndMonthString(d)).join(", ");

    return (
        <>
            <div className="row mb-3">
                <div className="col-md-3">
                    <label className="form-label mb-0" htmlFor="search-input">
                        Fritextsök
                    </label>
                    <input
                        className="form-control"
                        type="text"
                        id="search-input"
                        placeholder="Sök på plats eller lokalnamn"
                        value={
                            selectedFilterOptions && selectedFilterOptions.searchText
                                ? selectedFilterOptions.searchText
                                : ""
                        }
                        onChange={(event) => onSelectedFilterOptionsChange("searchText", event.target.value)}
                    />
                </div>
                <div className="col-md-3">
                    <label className="form-label mb-0" htmlFor="application-planning-status">
                        Programläggningsstatus
                    </label>
                    <select
                        className="form-select"
                        id="application-planning-status"
                        onChange={(event) => onSelectedFilterOptionsChange("sitePlanningStatus", event.target.value)}
                    >
                        <option value="">- Alla -</option>
                        {sitePlanningStatusOptions}
                    </select>
                </div>
                <div className="col-md-3">
                    <label className="form-label mb-0" htmlFor="available-dates-filter-options">
                        Tillgängliga datum
                    </label>
                    <div className="input-group">
                        <input
                            id="available-dates-filter-options"
                            type="text"
                            className="form-control"
                            readOnly={true}
                            onClick={() => setShowDateModal(true)}
                            value={availableDatesString}
                        />
                        <div className="input-group-append">
                            <button
                                className="btn btn-primary"
                                type="button"
                                style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                                onClick={() => setShowDateModal(true)}
                            >
                                <i className="bi bi-calendar-fill"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <DateFilterModal
                onDateAdded={dateAddedHandler}
                onDateRemoved={dateRemovedHandler}
                onHide={() => setShowDateModal(false)}
                selectedDates={selectedFilterOptions?.availableDates ?? []}
                show={showDateModal}
                applicationPeriod={applicationPeriod}
            />
        </>
    );
}

export default SitePlanningFilter;

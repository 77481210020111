import React, { useState } from "react";
import { isSameDay, toDayAndMonthString } from "../../../common/dateUtils";
import DateFilterModal from "../DateFilterModal";
import ApplicationPeriod from "../../../types/ApplicationPeriod";

export type SiteApplicationSelectionFilterOptions = {
    searchText: string;
    possibleOccasionDates: Date[];
};

type SiteApplicationSelectionFilterProps = {
    selectedFilterOptions: SiteApplicationSelectionFilterOptions;
    onSelectedFilterOptionsChange: (
        updatedProperty: keyof SiteApplicationSelectionFilterOptions,
        updatedValue: any
    ) => void;
    applicationPeriod: ApplicationPeriod;
};

function SiteApplicationSelectionFilter({
    selectedFilterOptions,
    onSelectedFilterOptionsChange,
    applicationPeriod
}: SiteApplicationSelectionFilterProps) {
    const [showDateModal, setShowDateModal] = useState(false);

    const dateAddedHandler = (addedDate: Date) => {
        const previousAvailableDates = selectedFilterOptions?.possibleOccasionDates ?? [];

        const updatedDates = [...previousAvailableDates, addedDate].sort((a, b) => a.getTime() - b.getTime());

        onSelectedFilterOptionsChange("possibleOccasionDates", updatedDates);
    };

    const dateRemovedHandler = (removedDate: Date) => {
        const updatedDates = selectedFilterOptions.possibleOccasionDates.filter((d) => !isSameDay(d, removedDate));

        onSelectedFilterOptionsChange("possibleOccasionDates", updatedDates);
    };

    const possibleOccasionDates = selectedFilterOptions?.possibleOccasionDates ?? [];
    const possibleOccasionDatesString = possibleOccasionDates.map((d) => toDayAndMonthString(d)).join(", ");

    return (
        <>
            <div className="row mb-2">
                <div className="col-md-3 form-group">
                    <label className="form-label mb-0" htmlFor="search-input">
                        Fritextsök
                    </label>
                    <input
                        className="form-control"
                        type="text"
                        id="search-input"
                        placeholder="Sök på namn eller aktör"
                        value={selectedFilterOptions ? selectedFilterOptions.searchText : ""}
                        onChange={(event) => onSelectedFilterOptionsChange("searchText", event.target.value)}
                    />
                </div>
                <div className="col-md-3">
                    <label className="form-label mb-0" htmlFor="available-dates-filter-options">
                        Möjliga datum
                    </label>
                    <div className="input-group">
                        <input
                            id="available-dates-filter-options"
                            type="text"
                            className="form-control"
                            readOnly={true}
                            onClick={() => setShowDateModal(true)}
                            value={possibleOccasionDatesString}
                        />
                        <div className="input-group-append">
                            <button
                                className="btn btn-primary"
                                type="button"
                                style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                                onClick={() => setShowDateModal(true)}
                            >
                                <i className="bi bi-calendar-fill"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <DateFilterModal
                onDateAdded={dateAddedHandler}
                onDateRemoved={dateRemovedHandler}
                onHide={() => setShowDateModal(false)}
                selectedDates={selectedFilterOptions?.possibleOccasionDates ?? []}
                show={showDateModal}
                applicationPeriod={applicationPeriod}
            />
        </>
    );
}

export default SiteApplicationSelectionFilter;
